import {DialogPosition, MatDialogConfig} from '@angular/material/dialog';

const rightAligned: DialogPosition = {
	top: '0',
	right: '0'
};
export class MatDialogDefaultConfigs {
	static rightAlignedDefaultConfig: MatDialogConfig = {
		position: rightAligned,
		height: '100vh',
		autoFocus: false,
		disableClose: true
	}
}
